<template>
  <loading v-if="isLoading" />
  <b-row v-else class="cart">
    <b-col cols="12" md="8">
      <b-card class="cart-container" v-if="cartData">
        <h2 class="mb-4">{{ $t("shopping_cart") }}</h2>
        <div v-if="cartData.items.length > 0">
          <div
            class="shopping-cart-item"
            v-for="(item, index) in cartData.items"
            :key="index"
          >
            <img
              width="120"
              height="120"
              v-if="item.itemable.image[0]"
              :src="item.itemable.image[0].path"
              :alt="item.itemable.name"
            />
            <div class="item-content">
              <p class="title">
                {{ $t("name") }} :
                <span class="mx-1 body">{{ item.itemable.name }}</span>
              </p>
              <p class="title">
                {{ $t("price") }} :
                <span class="mx-1 body">
                  {{
                    `${
                      item.itemable.sale
                        ? item.itemable.sale
                        : item.itemable.price
                    } ${currency}`
                  }}
                </span>
                <s v-if="item.itemable.sale">
                  {{ `${item.itemable.price} ${currency}` }}
                </s>
              </p>
              <div class="item-actions mt-2">
                <b-button variant="outline-danger" @click="deleteItem(item.id)">
                  {{ $t("remove_from_cart") }}
                </b-button>
              </div>
            </div>
            <hr
              v-if="cartData.items.length - 1 !== index"
              style="width: 100%"
            />
          </div>
        </div>
        <div v-else>
          <p style="text-align: center; font-size: 18px">
            {{ $t("there_is_no_items_in_the_cart") }}
          </p>
        </div>
      </b-card>
    </b-col>
    <b-col cols="12" md="4">
      <b-card class="coupon-container">
        <h2 class="mb-4">{{ $t("coupons") }}</h2>
        <div class="add-coupon">
          <b-form-input
            :disabled="!!cartData.coupon"
            v-model="coupon"
            :placeholder="
              cartData.coupon
                ? $t('you_already_added_coupon')
                : $t('enter_your_coupon')
            "
          />
          <b-button
            v-if="cartData.coupon"
            variant="danger"
            @click="removeCoupon"
          >
            {{ $t("remove_coupon") }}
          </b-button>
          <b-button v-else class="coupon-action" @click="addCoupon">{{
            $t("apply")
          }}</b-button>
        </div>
      </b-card>
      <b-card class="checkout-container">
        <h2 class="mb-4">{{ $t("cart_totals") }}</h2>
        <div class="chechout-item">
          <p class="title">{{ $t("subtotal") }}</p>
          <p class="body">
            {{ `${cartData.sub_total} ${currency}` }}
          </p>
        </div>
        <hr />
        <div class="chechout-item">
          <p class="title">{{ $t("discount") }}</p>
          <p class="body">
            {{ `${+cartData.sub_total - +cartData.final_total} ${currency}` }}
          </p>
        </div>
        <hr />
        <div class="chechout-item">
          <p class="title">{{ $t("total") }}</p>
          <p class="body">
            {{ `${cartData.final_total} ${currency}` }}
          </p>
        </div>
        <b-button @click="checkout" class="mt-3 checkout-action">
          {{ $t("checkout") }}
        </b-button>
      </b-card>
    </b-col>
    <overlay-component :isLoading="outerOverlay" />
  </b-row>
</template>

<script>
import Loading from "@/components/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import { BCard, BRow, BCol, BButton, BFormInput } from "bootstrap-vue";

export default {
  components: {
    Loading,
    BRow,
    BCard,
    BCol,
    BButton,
    BFormInput,
    OverlayComponent,
  },
  data() {
    return {
      coupon: null,
      cartData: null,
      isLoading: false,
      outerOverlay: false,
      poundIcon: require("@/assets/images/icons/pound.svg"),
    };
  },
  computed: {
    currency() {
      console.log(this.$store.state?.userData?.userData?.country?.currency);
      return this.$store.state?.userData?.userData?.country?.currency;
    },
  },
  created() {
    this.getCartData(false);
    if (this.$route.query.checkout) {
      this.$helpers.makeToast(
        "danger",
        this.$t("failed"),
        this.$t("checkout_has_been_failed")
      );
    }
  },
  methods: {
    async getCartData(overlay = false) {
      try {
        overlay ? (this.outerOverlay = true) : (this.isLoading = true);
        const res = await this.$http.get("/student/cart");
        this.cartData = res.data.data;
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        overlay ? (this.outerOverlay = false) : (this.isLoading = false);
      }
    },
    async deleteItem(itemId) {
      try {
        this.outerOverlay = true;
        await this.$http.delete(`/student/cart/${itemId}`);
        for (const item of this.cartData.items) {
          if (item.id === itemId) {
            this.cartData.items = this.cartData.items.filter(
              (item) => item.id !== itemId
            );
          }
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.outerOverlay = false;
      }
    },
    async addCoupon() {
      try {
        this.outerOverlay = true;

        const formData = new FormData();
        formData.append("coupon_code", this.coupon);
        await this.$http.post("/student/apply-coupon", formData);
        await this.getCartData(true);
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.outerOverlay = false;
      }
    },
    async removeCoupon() {
      try {
        this.outerOverlay = true;
        await this.$http.post("/student/detach-coupon");
        await this.getCartData(true);
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.outerOverlay = false;
      }
    },
    async checkout() {
      try {
        this.outerOverlay = true;
        const formData = new FormData();
        formData.append("success_url", "http://localhost:8080/#/checkout");
        formData.append(
          "cancel_url",
          "http://localhost:8080/#/cart?checkout=failed"
        );
        const res = await this.$http.post("/student/checkout", formData);
        if (res.data.url) {
          window.location.href = res.data.url;
        } else {
          this.$helpers.makeToast(
            "danger",
            this.$t("failed"),
            this.$t("checkout_failed")
          );
        }
        this.makeToast(
          "success",
          this.$t("success"),
          this.$t("checkout_completed_successfully")
        );
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.outerOverlay = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
